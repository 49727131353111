// utils/subscriptionUtils.js
export const SUBSCRIPTION_PLANS = {
  FREE: {
    id: 'free',
    name: 'Free',
    credits: 3,
    price: 0,
    monthlyPriceId: null,  // No Stripe price ID for free plan
    yearlyPriceId: null,   // No Stripe price ID for free plan
    features: [
      '月間3クレジット',
      '基本的なSEO最適化',
      '画像生成5枚/記事',
    ]
  },
  BASIC: {
    id: 'basic',
    name: 'Basic',
    credits: 15,
    price: 2980,
    monthlyPriceId: process.env.REACT_APP_STRIPE_BASIC_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.REACT_APP_STRIPE_BASIC_YEARLY_PRICE_ID,
    features: [
      '月間15クレジット',
      '基本的なSEO最適化',
      '画像生成5枚/記事',
      '優先サポート',
      'クレジット繰越可能'
    ]
  },
  ADVANCED: {
    id: 'advanced',
    name: 'Advanced',
    credits: 60,
    price: 9980,
    monthlyPriceId: process.env.REACT_APP_STRIPE_ADVANCED_MONTHLY_PRICE_ID,
    yearlyPriceId: process.env.REACT_APP_STRIPE_ADVANCED_YEARLY_PRICE_ID,
    features: [
      '月間60クレジット',
      '高度なSEO最適化',
      '画像生成無制限',
      '24時間サポート',
      'APIアクセス',
      'クレジット繰越可能',
      'カスタマイズ可能なプロンプト'
    ]
  }
};

export const SPECIAL_RANKS = {
  ENTERPRISE: {
    id: 'enterprise',
    name: 'Enterprise',
    credits: 9999
  },
  MODERATOR: {
    id: 'moderator',
    name: 'Moderator',
    credits: 9999
  },
  ADMIN: {
    id: 'admin',
    name: 'Admin',
    credits: 9999
  }
};

export const formatJPY = (amount) => {
  return new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(amount);
};

export const getMonthlyCredits = (plan) => {
  return SUBSCRIPTION_PLANS[plan]?.credits || 
         SPECIAL_RANKS[plan]?.credits || 
         SUBSCRIPTION_PLANS.FREE.credits;
};

export const getPlanDetails = (planId) => {
  const upperPlanId = planId?.toUpperCase();
  return SUBSCRIPTION_PLANS[upperPlanId] || SPECIAL_RANKS[upperPlanId];
};

export const isSpecialRank = (rank) => {
  return Object.keys(SPECIAL_RANKS).includes(rank?.toUpperCase());
};

export const calculateRemainingDays = (endDate) => {
  if (!endDate) return 0;
  const end = new Date(endDate);
  const now = new Date();
  const diffTime = end - now;
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const getPlanNameInJapanese = (planId) => {
  const planNames = {
    'free': '無料プラン',
    'basic': 'ベーシック',
    'advanced': 'アドバンス',
    'enterprise': 'エンタープライズ',
    'moderator': 'モデレーター',
    'admin': '管理者'
  };
  return planNames[planId] || planId;
};

export const getStatusInJapanese = (status) => {
  const statusNames = {
    'active': 'アクティブ',
    'trialing': 'トライアル中',
    'past_due': '支払い期限超過',
    'canceled': 'キャンセル済み',
    'incomplete': '未完了',
    'incomplete_expired': '期限切れ'
  };
  return statusNames[status] || status;
};