// articleApi.js
import axios from 'axios';
import { config } from '../config/environment';

const createAuthenticatedApi = (baseURL) => {
  const api = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

  api.interceptors.request.use(async (config) => {
    try {
      // Get active session
      if (!window.Clerk) {
        console.error('Clerk is not initialized');
        throw new Error('Clerk is not initialized');
      }

      const session = await window.Clerk.session;
      
      // Log session details (safely)
      console.log('Session details:', {
        hasSession: !!session,
        sessionId: session?.id,
        lastActiveAt: session?.lastActiveAt,
        status: session?.status
      });

      // Get the token with debugging
      let token;
      try {
        token = await session?.getToken();
        // Log token info (safely)
        console.log('Token info:', {
          exists: !!token,
          length: token?.length,
          prefix: token ? `${token.substring(0, 10)}...` : 'no token'
        });
      } catch (tokenError) {
        console.error('Error getting token:', tokenError);
        throw tokenError;
      }

      if (!token) {
        throw new Error('No auth token available');
      }

      // Set Authorization header
      config.headers.Authorization = `Bearer ${token}`;
      
      // Log final request configuration (safely)
      console.log('Request configuration:', {
        url: config.url,
        method: config.method,
        hasAuthHeader: !!config.headers.Authorization
      });

      return config;
    } catch (error) {
      console.error('Auth configuration error:', error);
      throw error;
    }
  });

  api.interceptors.response.use(
    response => response,
    error => {
      if (error.response) {
        console.error('API Response error:', {
          status: error.response.status,
          data: error.response.data,
          method: error.config.method,
          url: error.config.url
        });
      } else if (error.request) {
        console.error('API Request error:', {
          method: error.config.method,
          url: error.config.url
        });
      }
      return Promise.reject(error);
    }
  );

  return api;
};

const api = createAuthenticatedApi(`${config.apiBaseUrl}/api`);

export const generateNewArticle = async (keyword) => {
  try {
    // Verify Clerk session exists
    if (!window.Clerk?.session) {
      throw new Error('認証が必要です。再度ログインしてください。');
    }

    const response = await api.post('/generate-new-article', { keyword });
    return response.data;
  } catch (error) {
    console.error('Generate article error details:', {
      status: error.response?.status,
      message: error.message,
      data: error.response?.data
    });

    // Handle specific error cases
    if (error.message.includes('認証') || error.response?.status === 401) {
      throw new Error('認証が必要です。再度ログインしてください。');
    }
    if (error.response?.status === 403 || error.response?.status === 402) {
      throw new Error('クレジットが不足しています。');
    }
    if (error.response?.data?.error) {
      throw new Error(error.response.data.error);
    }
    
    throw new Error('記事の生成に失敗しました');
  }
};

export const getMyArticle = async (id) => {
  try {
    const response = await api.get(`/my-articles/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching article:', error);
    if (error.response?.status === 401) {
      throw new Error('認証が必要です。再度ログインしてください。');
    }
    throw new Error('記事の取得に失敗しました');
  }
};

export const generateSectionImage = async (articleId, sectionTitle) => {
  try {
    const response = await api.post('/generate-section-image', {
      articleId,
      sectionTitle
    });
    return response.data;
  } catch (error) {
    console.error('Error generating section image:', error);
    if (error.response?.status === 401) {
      throw new Error('認証が必要です。再度ログインしてください。');
    }
    throw new Error('画像の生成に失敗しました');
  }
};

// Add createQuickArticle function
export const createQuickArticle = async (keyword, language = '日本語') => {
  try {
    const response = await api.post('/quick-articles-full', { keyword, language });
    return response.data;
  } catch (error) {
    console.error('Error creating quick article:', error);
    if (error.response?.status === 401) {
      throw new Error('認証が必要です。再度ログインしてください。');
    }
    if (error.response?.status === 403) {
      throw new Error('クレジットが不足しています。');
    }
    throw new Error(error.response?.data?.error || 'クイック記事の作成に失敗しました');
  }
};