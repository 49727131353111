import React, { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useClerk, useUser } from '@clerk/clerk-react';
import { LogOut, Menu, X, CreditCard } from 'lucide-react';
import { useUserContext } from '../contexts/UserContext';

const Header = () => {
  const navigate = useNavigate();
  const { signOut } = useClerk();
  const { isSignedIn, user } = useUser();
  const { credits, loading } = useUserContext();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleSignOut = () => {
    signOut().then(() => {
      navigate('/');
    });
  };

  const navItems = [
    { name: 'ガイド', path: '/guide' },
    { name: 'プラン', path: '/pricing' },
    { name: 'カスタム入力', path: '/generate-outline' },
    { name: '記事一覧', path: '/article_list_new' },
  ];

  const isCurrentPath = (path) => {
    return location.pathname === path;
  };

  // Credit display component
  const CreditDisplay = () => {
    if (loading) {
      return (
        <div className="flex items-center gap-1 rounded-lg bg-gray-100 px-3 py-1.5">
          <div className="h-4 w-8 animate-pulse rounded bg-gray-200"></div>
        </div>
      );
    }

    return (
      <div className="flex items-center gap-1 rounded-lg bg-gray-100 px-3 py-1.5">
        <CreditCard size={14} className="text-gray-600" />
        <span className="text-sm font-medium text-gray-900">{credits.available}</span>
      </div>
    );
  };

  return (
    <header className="fixed top-2 z-30 w-full md:top-6">
      <div className="mx-auto max-w-6xl px-4 sm:px-6">
        <div className="relative flex h-14 items-center justify-between gap-3 rounded-2xl bg-white/90 px-3 shadow-lg shadow-black/[0.03] backdrop-blur-sm before:pointer-events-none before:absolute before:inset-0 before:rounded-[inherit] before:border before:border-transparent before:[background:linear-gradient(theme(colors.gray.100),theme(colors.gray.200))_border-box] before:[mask-composite:exclude_!important] before:[mask:linear-gradient(white_0_0)_padding-box,_linear-gradient(white_0_0)]">
          {/* Site branding */}
          <div className="flex items-center pl-2">
            <Link to="/" className="text-xl font-bold text-gray-800">
              Sense AISEO
            </Link>
          </div>

          {/* Desktop Navigation */}
          <nav className="hidden md:block">
            <ul className="flex items-center justify-center gap-8">
              {navItems.map((item) => (
                <li key={item.path}>
                  <Link
                    to={item.path}
                    className={`text-sm font-medium transition-colors hover:text-gray-900 ${
                      isCurrentPath(item.path)
                        ? 'text-gray-900'
                        : 'text-gray-600'
                    }`}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          {/* Desktop auth buttons */}
          <div className="hidden items-center justify-end gap-3 md:flex">
            {isSignedIn ? (
              <>
                {/* Credit Display */}
                <CreditDisplay />
                <Link
                  to="/profile"
                  className="btn-sm bg-white text-gray-800 shadow hover:bg-gray-50"
                >
                  My Page
                </Link>
                <button
                  onClick={handleSignOut}
                  className="btn-sm bg-gray-800 text-gray-200 shadow hover:bg-gray-900"
                  title="Sign out"
                >
                  <LogOut size={16} />
                </button>
              </>
            ) : (
              <>
                <Link
                  to="/sign-in"
                  className="btn-sm bg-white text-gray-800 shadow hover:bg-gray-50"
                >
                  ログイン
                </Link>
                <Link
                  to="/sign-up"
                  className="btn-sm bg-gray-800 text-gray-200 shadow hover:bg-gray-900"
                >
                  登録
                </Link>
              </>
            )}
          </div>

          {/* Mobile menu button */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="ml-auto rounded-lg p-2 text-gray-600 hover:bg-gray-100 md:hidden"
          >
            {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>

        {/* Mobile menu */}
        {isMobileMenuOpen && (
          <div className="absolute left-4 right-4 top-[calc(100%+0.5rem)] rounded-2xl bg-white/90 p-4 shadow-lg backdrop-blur-sm md:hidden">
            <nav className="mb-4">
              <ul className="space-y-3">
                {navItems.map((item) => (
                  <li key={item.path}>
                    <Link
                      to={item.path}
                      onClick={() => setIsMobileMenuOpen(false)}
                      className={`block rounded-lg px-3 py-2 text-sm font-medium transition-colors hover:bg-gray-100 ${
                        isCurrentPath(item.path)
                          ? 'bg-gray-100 text-gray-900'
                          : 'text-gray-600'
                      }`}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
            
            {/* Mobile auth buttons */}
            <div className="space-y-2">
              {isSignedIn ? (
                <>
                  {/* Mobile Credit Display */}
                  <div className="mb-2 flex justify-center">
                    <CreditDisplay />
                  </div>
                  <Link
                    to="/profile"
                    onClick={() => setIsMobileMenuOpen(false)}
                    className="block w-full rounded-xl bg-white px-4 py-2.5 text-center text-sm font-medium text-gray-800 shadow hover:bg-gray-50"
                  >
                    {user.firstName}
                  </Link>
                  <button
                    onClick={() => {
                      handleSignOut();
                      setIsMobileMenuOpen(false);
                    }}
                    className="flex w-full items-center justify-center rounded-xl bg-gray-800 px-4 py-2.5 text-sm font-medium text-gray-200 shadow hover:bg-gray-900"
                  >
                    <LogOut size={16} className="mr-2" />
                    ログアウト
                  </button>
                </>
              ) : (
                <>
                  <Link
                    to="/sign-in"
                    onClick={() => setIsMobileMenuOpen(false)}
                    className="block w-full rounded-xl bg-white px-4 py-2.5 text-center text-sm font-medium text-gray-800 shadow hover:bg-gray-50"
                  >
                    ログイン
                  </Link>
                  <Link
                    to="/sign-up"
                    onClick={() => setIsMobileMenuOpen(false)}
                    className="block w-full rounded-xl bg-gray-800 px-4 py-2.5 text-center text-sm font-medium text-gray-200 shadow hover:bg-gray-900"
                  >
                    登録
                  </Link>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      <style>{`
        .btn-sm {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.75rem;
          padding: 0.5rem 1rem;
          font-size: 0.875rem;
          font-weight: 500;
          transition-property: all;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 150ms;
        }
      `}</style>
    </header>
  );
};

export default Header;