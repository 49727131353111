import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import { useUserContext } from '../contexts/UserContext';
import { createQuickArticle } from '../api/articleApi';
import PageIllustration from './PageIllustration';

function HomePage() {
  const [keyword, setKeyword] = useState('');
  const [language, setLanguage] = useState('日本語');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const navigate = useNavigate();
  const { isLoaded, isSignedIn } = useUser();
  const { credits } = useUserContext();

  const getStatusMessage = (currentProgress) => {
    if (currentProgress < 25) return 'Market analysis...';
    if (currentProgress < 50) return 'SEO optimization...';
    if (currentProgress < 75) return 'Image creation...';
    if (currentProgress < 100) return 'Outline creation...';
    return '生成完了！';
  };

  const simulateProgress = () => {
    setShowProgress(true);
    setProgress(0);
    
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress === 100) {
          clearInterval(timer);
          return 100;
        }
        const diff = 100 - prevProgress;
        const increment = Math.max(diff * 0.1, 1);
        const newProgress = Math.min(prevProgress + increment, 95);
        setStatusMessage(getStatusMessage(newProgress));
        return newProgress;
      });
    }, 800);

    return timer;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!keyword.trim()) {
      setError('キーワードを入力してください');
      return;
    }

    if ((credits?.available || 0) < 1) {
      setError('クレジットが不足しています');
      return;
    }

    setLoading(true);
    setError('');
    const timer = simulateProgress();

    try {
      const response = await createQuickArticle(keyword.trim(), language);
      clearInterval(timer);
      setProgress(100);
      setStatusMessage(getStatusMessage(100));
      setTimeout(() => {
        navigate(`/articles/${response.id}`);
      }, 500);
    } catch (err) {
      clearInterval(timer);
      setError(err.message || '記事の生成に失敗しました');
      setShowProgress(false);
      setProgress(0);
      setStatusMessage('');
    } finally {
      setLoading(false);
    }
  };

  if (!isLoaded) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const renderContent = () => {
    if (!isSignedIn) {
      return (
        <div className="text-center animate-fade-in">
          <h2 className="mb-6 text-3xl font-bold tracking-[-0.037em] text-gray-800">ログインしてください</h2>
          <button
            onClick={() => navigate('/sign-in')}
            className="btn group relative inline-flex items-center bg-gradient-to-t from-gray-800 to-gray-700 px-6 sm:px-8 py-3 text-white shadow-sm hover:to-gray-600 transition-all rounded-xl"
          >
            ログイン
            <span className="ml-2 tracking-normal text-gray-300 transition-transform duration-150 ease-in-out group-hover:translate-x-0.5">
              →
            </span>
          </button>
        </div>
      );
    }

    return (
      <div className="mx-auto max-w-3xl px-4 sm:px-6 animate-fade-in">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="relative flex flex-col sm:flex-row p-2 rounded-2xl bg-white/90 shadow-xl backdrop-blur-sm">
            <input
              type="text"
              placeholder="キーワードを入力"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              disabled={loading}
              className="w-full p-4 outline-none text-gray-600 bg-transparent border-0 focus:ring-0"
            />
            <div className="relative mt-2 sm:mt-0">
              <select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                disabled={loading}
                className="w-full sm:w-auto appearance-none p-4 outline-none text-gray-600 border-t border-gray-200 sm:border-l sm:border-gray-200 bg-transparent focus:ring-0 pr-8"
              >
                <option value="日本語">日本語</option>
                <option value="英語">英語</option>
              </select>
            </div>
            <button
              type="submit"
              disabled={loading}
              className="btn-sm group mt-2 sm:mt-0 sm:ml-2 bg-gradient-to-t from-gray-800 to-gray-700 text-white hover:to-gray-600 px-8 py-4 rounded-xl"
            >
              {loading ? (
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
              ) : (
                <span className="relative inline-flex items-center">
                  生成
                  <span className="ml-2 tracking-normal text-gray-300 transition-transform duration-150 ease-in-out group-hover:translate-x-0.5">
                    →
                  </span>
                </span>
              )}
            </button>
          </div>

          {showProgress && (
            <div className="relative rounded-2xl bg-white/90 p-6 shadow-xl backdrop-blur-sm animate-fade-in">
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div 
                  className="bg-gradient-to-r from-gray-800 to-gray-600 h-2.5 rounded-full transition-all duration-300"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
              <p className="text-center text-gray-600 mt-4 font-medium">
                {statusMessage} ({Math.round(progress)}%)
              </p>
            </div>
          )}

          {error && (
            <div className="relative rounded-2xl bg-white/90 p-4 shadow-xl backdrop-blur-sm animate-fade-in">
              <p className="text-center text-red-600">{error}</p>
            </div>
          )}
        </form>
      </div>
    );
  };

  return (
    <section className="relative min-h-screen">
      <PageIllustration />
      <div className="relative z-10 mx-auto max-w-6xl px-4 sm:px-6">
        <div className="pb-12 pt-32 md:pb-20 md:pt-40">
          <div className="pb-12 text-center md:pb-16">
            <h1
              className="mb-4 text-2xl sm:text-4xl md:text-5xl lg:text-6xl font-bold tracking-[-0.037em] px-4 sm:px-0 animate-fade-in-up"
              style={{
                textShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                animation: 'fade-in-up 1s ease-out'
              }}
            >
              キーワード入力だけで、<br />プロ品質のSEO記事が完成
            </h1>
            <p 
              className="text-base sm:text-lg text-gray-600 mb-8 px-4 sm:px-0 animate-fade-in"
              style={{
                animation: 'fade-in 1s ease-out 0.3s backwards'
              }}
            >
              <strong>AIが実現する、新時代のコンテンツ作成<br className="hidden sm:block" />3分で完成｜SEO対策済み｜画像最適化付き</strong>
            </p>
            {renderContent()}
          </div>
        </div>
      </div>

      <style>{`
        @keyframes fade-in {
          from {
            opacity: 0;
            transform: translateY(10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes fade-in-up {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-fade-in {
          animation: fade-in 1s ease-out;
        }

        .animate-fade-in-up {
          animation: fade-in-up 1s ease-out;
        }
      `}</style>
    </section>
  );
}

export default HomePage;