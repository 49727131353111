import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import PageIllustration from './PageIllustration';
import { SUBSCRIPTION_PLANS, formatJPY } from '../utils/subscriptionUtils';
import { subscriptionService } from '../services/subscriptionService';
import { toast } from 'react-hot-toast';

const PricingPage = () => {
  const [isYearly, setIsYearly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processingPlanId, setProcessingPlanId] = useState(null);
  const navigate = useNavigate();
  const { isSignedIn } = useUser();

  const getDiscountedPrice = (price) => {
    return isYearly ? Math.round(price * 0.8) : price;
  };

  const handleSubscribe = async (plan) => {
    if (!isSignedIn) {
      navigate('/sign-in');
      return;
    }

    try {
      setLoading(true);
      setProcessingPlanId(plan.id);

      const { url } = await subscriptionService.createCheckoutSession({
        priceId: isYearly ? plan.yearlyPriceId : plan.monthlyPriceId,
        isYearly
      });

      // Save plan selection to localStorage before redirecting
      localStorage.setItem('selectedPlan', JSON.stringify({
        planId: plan.id,
        isYearly
      }));

      window.location.href = url;
    } catch (error) {
      console.error('Subscription error:', error);
      toast.error('サブスクリプションの処理中にエラーが発生しました。後でもう一度お試しください。');
    } finally {
      setLoading(false);
      setProcessingPlanId(null);
    }
  };

  const renderPlanButton = (plan) => {
    const isProcessing = processingPlanId === plan.id;

    if (plan.id === 'free') {
      return (
        <button
          className="inline-flex items-center justify-center w-full px-4 py-3 mt-6 font-semibold text-gray-900 transition-all duration-200 bg-transparent border-2 rounded-xl border-gray-800 hover:bg-gray-800 hover:text-white disabled:opacity-50"
          onClick={() => navigate('/sign-up')}
          disabled={loading}
        >
          無料で始める
        </button>
      );
    }

    return (
      <button
        className={`inline-flex items-center justify-center w-full px-4 py-3 mt-6 font-semibold transition-all duration-200 ${
          plan.id === 'advanced'
            ? 'text-white border-2 border-transparent bg-gray-800 hover:opacity-80'
            : 'text-gray-900 border-2 border-gray-800 bg-transparent hover:bg-gray-800 hover:text-white'
        } disabled:opacity-50`}
        onClick={() => handleSubscribe(plan)}
        disabled={loading}
      >
        {isProcessing ? (
          <div className="flex items-center">
            <div className="w-5 h-5 mr-2 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
            処理中...
          </div>
        ) : (
          `${formatJPY(getDiscountedPrice(plan.price))}/${isYearly ? '月（年間契約）' : '月'}で始める`
        )}
      </button>
    );
  };

  const renderPlanFeatures = (features) => (
    <ul className="flex flex-col mt-8 space-y-4">
      {features.map((feature, index) => (
        <li key={index} className="inline-flex items-center space-x-2">
          <svg
            className="flex-shrink-0 w-5 h-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
          <span className="text-base font-medium text-gray-900">{feature}</span>
        </li>
      ))}
    </ul>
  );

  return (
    <section className="relative min-h-screen">
      <PageIllustration />
      <div className="relative z-10 pt-32 md:pt-40">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-[-0.037em] text-gray-800 sm:text-4xl lg:text-5xl animate-fade-in-up">
              シンプルな料金プラン
            </h2>
            <p className="max-w-md mx-auto mt-4 text-lg text-gray-600 animate-fade-in">
              必要な機能を、必要な分だけ。
              スケーラブルな料金体系で、ビジネスの成長をサポートします。
            </p>
          </div>

          <div className="mt-10 animate-fade-in">
            <div className="flex items-center justify-center space-x-2.5">
              <span className="text-base font-medium text-gray-900">月額</span>
              <button
                onClick={() => setIsYearly(!isYearly)}
                type="button"
                className={`relative inline-flex h-6 w-12 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none ${
                  isYearly ? 'bg-gray-800' : 'bg-gray-200'
                }`}
                role="switch"
                aria-checked={isYearly}
              >
                <span
                  className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                    isYearly ? 'translate-x-6' : 'translate-x-0'
                  }`}
                />
              </button>
              <span className="text-base font-medium text-gray-900">年額 (20% OFF)</span>
            </div>
          </div>

          <div className="grid max-w-6xl grid-cols-1 gap-6 mx-auto mt-8 sm:mt-14 sm:grid-cols-2 lg:grid-cols-3 md:gap-9 animate-fade-in">
            {Object.values(SUBSCRIPTION_PLANS).map((plan) => (
              <div
                key={plan.id}
                className={`overflow-hidden bg-white/90 backdrop-blur-sm rounded-2xl border-2 ${
                  plan.id === 'advanced'
                    ? 'border-gray-800'
                    : 'border-gray-200'
                } hover:scale-102 transition-all duration-200`}
              >
                <div className="p-6 md:py-8 md:px-9">
                  <h3 className="text-xl font-semibold text-gray-800">
                    {plan.name}
                  </h3>
                  <p className="mt-2.5 text-sm text-gray-600">
                    {plan.id === 'free'
                      ? '個人向け無料プラン'
                      : plan.id === 'basic'
                      ? '個人・フリーランス向け'
                      : 'プロフェッショナル向け'}
                  </p>

                  <div className="flex items-end mt-5">
                    <div className="flex items-start">
                      <span className="text-xl font-medium text-gray-800">¥</span>
                      <p className="text-6xl font-medium tracking-tight text-gray-800">
                        {getDiscountedPrice(plan.price).toLocaleString()}
                      </p>
                    </div>
                    <span className="ml-0.5 text-lg text-gray-600">/ {isYearly ? '月*' : '月'}</span>
                  </div>
                  {isYearly && (
                    <p className="mt-2 text-sm text-gray-500">*年間契約の場合</p>
                  )}

                  {renderPlanButton(plan)}
                  {renderPlanFeatures(plan.features)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <style>{`
        @keyframes fade-in {
          from {
            opacity: 0;
            transform: translateY(8px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes fade-in-up {
          from {
            opacity: 0;
            transform: translateY(12px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-fade-in {
          animation: fade-in 0.5s ease-out;
        }

        .animate-fade-in-up {
          animation: fade-in-up 0.5s ease-out;
        }

        .hover\\:scale-102:hover {
          transform: scale(1.02);
        }
      `}</style>
    </section>
  );
};

export default PricingPage;