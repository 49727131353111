import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useUser, useAuth, useClerk } from '@clerk/clerk-react';
import { subscriptionService } from '../services/subscriptionService';
import { toast } from 'react-hot-toast';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { isSignedIn, isLoaded } = useAuth();
  const { user } = useUser();
  const { session } = useClerk();
  const [credits, setCredits] = useState({ total: 0, available: 0, reserved: 0 });
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Authenticated fetch helper
  const authenticatedFetch = useCallback(async (url, options = {}) => {
    if (!session) {
      throw new Error('認証が必要です。再度ログインしてください。');
    }

    try {
      const token = await session.getToken();
      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error('認証が必要です。再度ログインしてください。');
        }
        throw new Error('リクエストに失敗しました');
      }

      return await response.json();
    } catch (error) {
      console.error('API call failed:', error);
      throw error;
    }
  }, [session]);

  // Fetch user data
  const fetchUserData = useCallback(async () => {
    if (!isSignedIn || !session) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      setError(null);

      console.log('Fetching subscription data with auth state:', {
        isSignedIn,
        hasSession: !!session,
        sessionId: session?.id
      });

      const subscriptionData = await subscriptionService.getCurrentSubscription();
      setSubscription(subscriptionData);
      setCredits({
        total: subscriptionData.credits,
        available: subscriptionData.credits - (subscriptionData.reserved_credits || 0),
        reserved: subscriptionData.reserved_credits || 0
      });

    } catch (err) {
      console.error('Error fetching user data:', err);
      setError('ユーザーデータの取得に失敗しました');
      
      if (err.message.includes('認証')) {
        toast.error('認証が必要です。再度ログインしてください。');
      } else {
        toast.error('ユーザーデータの取得に失敗しました');
      }
    } finally {
      setLoading(false);
    }
  }, [isSignedIn, session]);

  // Credit management functions
  const reserveCredits = useCallback(async (amount, purpose) => {
    try {
      const data = await authenticatedFetch('/api/credits/reserve', {
        method: 'POST',
        body: JSON.stringify({ amount, purpose })
      });

      setCredits(prevCredits => ({
        ...prevCredits,
        available: prevCredits.available - amount,
        reserved: prevCredits.reserved + amount
      }));

      return data.reservationId;
    } catch (error) {
      console.error('Error reserving credits:', error);
      throw error;
    }
  }, [authenticatedFetch]);

  // eslint-disable-next-line no-unused-vars
  const confirmCreditUse = useCallback(async (reservationId) => {
    const data = await authenticatedFetch('/api/credits/confirm', {
      method: 'POST',
      body: JSON.stringify({ reservationId })
    });
    setCredits(data.credits);
  }, [authenticatedFetch]);

  // eslint-disable-next-line no-unused-vars
  const releaseCredits = useCallback(async (reservationId) => {
    const data = await authenticatedFetch('/api/credits/release', {
      method: 'POST',
      body: JSON.stringify({ reservationId })
    });
    setCredits(data.credits);
  }, [authenticatedFetch]);

  // eslint-disable-next-line no-unused-vars
  const handleSubscriptionChange = useCallback(async () => {
    const portal = await subscriptionService.createPortalSession();
    window.location.href = portal.url;
  }, []);

  // Auth state effect
  useEffect(() => {
    if (isLoaded && isSignedIn && session) {
      console.log('Auth state changed, fetching user data');
      fetchUserData();
    } else if (isLoaded && !isSignedIn) {
      setSubscription(null);
      setCredits({ total: 0, available: 0, reserved: 0 });
    }
  }, [isLoaded, isSignedIn, session, fetchUserData]);

  // Checkout success effect
  useEffect(() => {
    const handleCheckoutSuccess = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const sessionId = queryParams.get('session_id');

      if (sessionId && isSignedIn && session) {
        try {
          await subscriptionService.verifyCheckoutSession(sessionId);
          await fetchUserData();
          toast.success('サブスクリプションの更新が完了しました');
        } catch (error) {
          console.error('Error verifying checkout:', error);
          if (error.message.includes('認証')) {
            toast.error('認証が必要です。再度ログインしてください。');
          } else {
            toast.error('サブスクリプションの確認に失敗しました');
          }
        }
      }
    };

    handleCheckoutSuccess();
  }, [isSignedIn, session, fetchUserData]);

  const value = {
    credits,
    subscription,
    loading,
    error,
    refreshUserData: fetchUserData,
    hasSufficientCredits: useCallback((required = 1) => credits.available >= required, [credits]),
    reserveCredits,
    confirmCreditUse,
    releaseCredits,
    handleSubscriptionChange,
    isSignedIn,
    user
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};

export default UserContext;