// config/environment.js
export const config = {
  apiBaseUrl: process.env.NODE_ENV === 'production' 
    ? 'https://www.senseaiseo.com' 
    : 'http://localhost:5000',  // Your Flask server port
  
  // Add CORS settings
  corsSettings: {
    credentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': process.env.NODE_ENV === 'production' 
        ? 'https://www.senseaiseo.com' 
        : 'http://localhost:3000'
    }
  }
};