// components/credit/TransactionHistory.js
import React, { useState, useEffect } from 'react';
import { useUserContext } from '../../contexts/UserContext';
import { TransactionStatusBadge } from './CreditAlerts';

const TransactionHistory = () => {
  const { getCreditHistory } = useUserContext();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setLoading(true);
        const history = await getCreditHistory();
        setTransactions(history);
        setError(null);
      } catch (err) {
        setError('取引履歴の取得に失敗しました');
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [getCreditHistory]);

  if (loading) {
    return (
      <div className="animate-pulse space-y-4">
        {[...Array(5)].map((_, i) => (
          <div key={i} className="h-16 rounded-lg bg-gray-100"></div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-lg bg-red-50 p-4">
        <p className="text-sm text-red-800">{error}</p>
      </div>
    );
  }

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('ja-JP', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold text-gray-900">クレジット履歴</h3>
      <div className="overflow-hidden rounded-lg border border-gray-200 bg-white shadow">
        <ul className="divide-y divide-gray-200">
          {transactions.map((transaction) => (
            <li key={transaction._id} className="px-6 py-4">
              <div className="flex items-center justify-between">
                <div className="flex-1">
                  <div className="flex items-center gap-3">
                    <TransactionStatusBadge status={transaction.status} />
                    <p className="text-sm font-medium text-gray-900">
                      {transaction.description || transaction.type}
                    </p>
                  </div>
                  <p className="mt-0.5 text-xs text-gray-500">
                    {formatDate(transaction.created_at)}
                  </p>
                </div>
                <div className="ml-4 flex flex-col items-end">
                  <span className={`text-sm font-medium ${
                    transaction.type === 'credit_use' 
                      ? 'text-red-600' 
                      : 'text-green-600'
                  }`}>
                    {transaction.type === 'credit_use' ? '-' : '+'}
                    {transaction.amount}
                  </span>
                  {transaction.reference_id && (
                    <span className="mt-0.5 text-xs text-gray-500">
                      ID: {transaction.reference_id}
                    </span>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TransactionHistory;