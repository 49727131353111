import React, { useState, useEffect, createContext, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getMyArticle } from '../api/articleApi';
import { ChevronRight } from 'lucide-react';
import PageIllustration from './PageIllustration';
import ContentParser from './ContentParser';
import SectionImage from './SectionImage';
import { useAuth } from "@clerk/clerk-react";
import RegenerateSection from './RegenerateSection';
import DownloadButtons from './DownloadButtons';

// Theme Context
const ThemeContext = createContext();

// Theme styles
const themes = {
  default: {
    background: "bg-gray-50",
    container: "bg-white rounded-xl shadow-lg",
    heading1: "text-4xl md:text-5xl font-extrabold leading-tighter tracking-tighter mb-4",
    heading2: "text-2xl font-bold text-gray-900",
    heading3: "text-xl font-semibold text-gray-800",
    paragraph: "text-gray-600 leading-relaxed",
    sidebar: "w-64 bg-white rounded-xl shadow-lg p-6 h-fit sticky top-24",
    sidebarLink: "block py-2 px-3 text-sm text-gray-700 hover:bg-gray-100 rounded-md transition-colors duration-150",
    sidebarSubLink: "block py-1 px-3 text-xs text-gray-600 hover:bg-gray-100 rounded-md transition-colors duration-150",
    ctaButton: "group relative flex items-center justify-center px-6 py-3 mt-6 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors duration-150 shadow-md hover:shadow-lg",
    faqContainer: "bg-gray-50 p-6 rounded-lg",
    contentContainer: "flex-1 bg-white rounded-xl shadow-lg pb-8 pr-8 pl-8",
    metadata: "text-gray-500",
    mainWrapper: "max-w-7xl mx-auto px-4 sm:px-6",
    contentWrapper: "pt-32 pb-12 md:pt-40 md:pb-20"
  },
  modern: {
    background: "bg-slate-950",
    container: "bg-slate-900 rounded-xl shadow-lg",
    heading1: "text-4xl md:text-5xl font-bold leading-tight tracking-tight mb-4 text-white",
    heading2: "text-2xl font-bold text-emerald-400",
    heading3: "text-xl font-medium text-slate-200",
    paragraph: "text-slate-300 leading-relaxed",
    sidebar: "w-64 bg-slate-800 rounded-xl shadow-lg p-6 h-fit sticky top-24",
    sidebarLink: "block py-2 px-3 text-sm text-slate-300 hover:bg-slate-700 rounded-md transition-colors duration-150",
    sidebarSubLink: "block py-1 px-3 text-xs text-slate-400 hover:bg-slate-700 rounded-md transition-colors duration-150",
    ctaButton: "group relative flex items-center justify-center px-6 py-3 mt-6 bg-emerald-500 hover:bg-emerald-600 text-white rounded-lg transition-colors duration-150 shadow-md hover:shadow-lg",
    faqContainer: "bg-slate-800 p-6 rounded-lg border border-slate-700",
    contentContainer: "flex-1 bg-slate-900 rounded-xl shadow-lg pb-8 pr-8 pl-8",
    metadata: "text-slate-400",
    mainWrapper: "max-w-7xl mx-auto px-4 sm:px-6",
    contentWrapper: "pt-32 pb-12 md:pt-40 md:pb-20"
  },
  generated: {
    background: "bg-gray-100 font-['Helvetica_Neue',Arial,'Hiragino_Kaku_Gothic_ProN','Hiragino_Sans',Meiryo,sans-serif]",
    container: "bg-white/90 backdrop-blur-sm rounded-2xl shadow-lg",
    heading1: "text-3xl font-bold tracking-[-0.037em] text-gray-800",
    heading2: "relative text-base md:text-xl font-bold bg-[#e85fb1] text-white py-3 px-4 mt-2 mb-8 z-10 clear-both leading-relaxed before:absolute before:block before:pointer-events-none before:content-[''] before:-top-1 before:left-0 before:w-full before:h-[calc(100%+1rem)] before:box-content before:border-t-2 before:border-[#e85fb1]",
    heading3: "relative text-base md:text-lg font-bold leading-relaxed mt-12 mb-8 pb-2 px-2 clear-both before:absolute before:content-[''] before:w-full before:h-0.5 before:bg-[#e85fb1] before:bottom-0 before:left-0 before:block before:z-0",
    paragraph: "text-gray-600 leading-relaxed",
    sidebar: "w-64 bg-white/90 backdrop-blur-sm rounded-2xl shadow-lg p-6 h-fit sticky top-24",
    sidebarLink: "block py-2 px-3 text-sm text-gray-600 hover:bg-gray-50 rounded-xl transition-colors duration-150",
    sidebarSubLink: "block py-1 px-3 text-xs text-gray-500 hover:bg-gray-50 rounded-xl transition-colors duration-150",
    ctaButton: "group relative flex items-center justify-center px-6 py-3 mt-6 bg-gradient-to-t from-gray-800 to-gray-700 text-white rounded-xl transition-colors duration-150 shadow-sm hover:to-gray-600",
    faqContainer: "bg-gray-50 p-6 rounded-xl",
    contentContainer: "flex-1 bg-white/90 backdrop-blur-sm rounded-2xl shadow-lg p-6 md:p-8",
    metadata: "text-gray-500",
    mainWrapper: "max-w-4xl mx-auto px-4 sm:px-6",
    contentWrapper: "pt-32 pb-12 md:pt-40 md:pb-20"
  }
};

const ThemeSelector = ({ currentTheme, setCurrentTheme }) => {
  return (
    <div className="absolute top-4 right-4 z-20">
      <select 
        value={currentTheme} 
        onChange={(e) => setCurrentTheme(e.target.value)}
        className={`${
          currentTheme === 'modern' 
            ? 'bg-slate-800 border-slate-600 text-white' 
            : currentTheme === 'generated' 
              ? 'bg-white/90 backdrop-blur-sm border-gray-200' 
              : 'bg-white border-gray-300'
        } border rounded-xl py-2 px-4 shadow-sm focus:outline-none transition-colors duration-300`}
      >
        <option value="default">Default Theme</option>
        <option value="modern">Modern Dark Theme</option>
        <option value="generated">Generated Theme</option>
      </select>
    </div>
  );
};

const CTAButton = ({ text }) => {
  const { currentTheme } = useContext(ThemeContext);
  return (
    <button className={themes[currentTheme].ctaButton}>
      <span className="text-base font-medium">{text}</span>
      <ChevronRight className="w-5 h-5 ml-1 transform group-hover:translate-x-1 transition-transform duration-150" />
    </button>
  );
};

const TableOfContents = ({ article }) => {
  const { currentTheme } = useContext(ThemeContext);
  const createId = (title) => title.toLowerCase().replace(/\s+/g, '-');

  const scrollToSection = (e, sectionId) => {
    e.preventDefault();
    const element = document.getElementById(sectionId);
    if (element) {
      const headerOffset = window.innerWidth >= 768 ? 96 : 64;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className={themes[currentTheme].sidebar}>
      <h2 className={themes[currentTheme].heading2}>目次</h2>
      <nav className="space-y-1">
        {article.outline.H2Sections.map((section, index) => (
          <div key={index}>
            <a
              href={`#${createId(section.H2Title)}`}
              onClick={(e) => scrollToSection(e, createId(section.H2Title))}
              className={themes[currentTheme].sidebarLink}
            >
              {section.H2Title}
            </a>
            <div className="ml-4 space-y-1">
              {section.H3Sections.map((subSection, subIndex) => (
                <a
                  key={subIndex}
                  href={`#${createId(subSection.H3Title)}`}
                  onClick={(e) => scrollToSection(e, createId(subSection.H3Title))}
                  className={themes[currentTheme].sidebarSubLink}
                >
                  {subSection.H3Title}
                </a>
              ))}
            </div>
          </div>
        ))}
        {article.faq && article.faq.length > 0 && (
          <a
            href="#faq"
            onClick={(e) => scrollToSection(e, 'faq')}
            className={themes[currentTheme].sidebarLink}
          >
            よくある質問
          </a>
        )}
      </nav>
    </div>
  );
};

const MyArticleView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { getToken, isSignedIn } = useAuth();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTheme, setCurrentTheme] = useState('default');

  useEffect(() => {
    if (!isSignedIn) {
      navigate('/sign-in');
      return;
    }

    const fetchArticle = async () => {
      try {
        // Add error check for ID
        if (!id) {
          throw new Error('Article ID not found');
        }

        const token = await getToken();
        const data = await getMyArticle(id, token);
        setArticle(data);
        setError(null);
      } catch (err) {
        console.error('Error fetching article:', err);
        if (err.message?.includes('Authentication required')) {
          navigate('/sign-in');
        } else {
          setError(err.message || '記事の取得に失敗しました');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id, getToken, isSignedIn, navigate]);

  const createId = (title) => title.toLowerCase().replace(/\s+/g, '-');

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="text-red-500 mb-4">{error}</div>
        <button
          onClick={() => navigate(-1)}
          className="text-blue-500 hover:text-blue-700"
        >
          戻る
        </button>
      </div>
    );
  }

  if (!article) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="text-gray-500 mb-4">記事が見つかりませんでした</div>
        <button
          onClick={() => navigate(-1)}
          className="text-blue-500 hover:text-blue-700"
        >
          戻る
        </button>
      </div>
    );
  }

  return (
    <ThemeContext.Provider value={{ currentTheme, setCurrentTheme }}>
      <div className={`min-h-screen transition-colors duration-300 ${themes[currentTheme].background}`}>
        <section className="relative scroll-mt-24">
          <ThemeSelector currentTheme={currentTheme} setCurrentTheme={setCurrentTheme} />
          <PageIllustration />
          <div className={`relative z-10 ${themes[currentTheme].mainWrapper}`}>
            <div className={themes[currentTheme].contentWrapper}>
              {/* Article Header */}
              <div className="text-center pb-12 md:pb-2">
                <h1 className={themes[currentTheme].heading1}>
                  {article.title}
                </h1>
                <div className={`text-sm ${themes[currentTheme].metadata} mb-8`}>
                  作成日: {new Date(article.metadata.createdAt).toLocaleDateString()}
                </div>
              </div>

              {/* Article image banner */}
              {article.imageBanner && article.imageBanner.url && (
                <div className="mb-12">
                  <img
                    src={article.imageBanner.url}
                    alt={article.title}
                    className="w-full h-96 object-cover rounded-xl shadow-lg"
                    onError={(e) => {
                      e.target.onerror = null;
                      console.error('Failed to load image:', article.imageBanner.url);
                      e.target.style.display = 'none';
                    }}
                  />
                </div>
              )}

              {/* Article Intro */}
              {article.intro && (
                <div className="max-w-3xl mx-auto mb-12 text-center">
                  <p className={themes[currentTheme].paragraph}>
                    {article.intro}
                  </p>
                </div>
              )}

              {/* Main Content with Sidebar */}
              <div className="flex gap-8">
                {/* Table of Contents Sidebar */}
                <TableOfContents article={article} />

                {/* Article Content */}
                <div className={themes[currentTheme].contentContainer}>
                  {article.outline.H2Sections.map((section, index) => (
                    <div 
                      key={index} 
                      className="space-y-4 scroll-mt-24 pt-8" 
                      id={createId(section.H2Title)}
                    >
                      <h2 className={themes[currentTheme].heading2}>
                        {section.H2Title}
                      </h2>

                      <SectionImage 
                        articleId={id}
                        sectionTitle={section.H2Title}
                        currentTheme={currentTheme}
                        existingImage={section.sectionImage}
                      />

                      {section.H3Sections.map((subSection, subIndex) => (
                        <div 
                          key={subIndex} 
                          className="space-y-4 scroll-mt-24" 
                          id={createId(subSection.H3Title)}
                        >
                          <div className="flex justify-between items-center">
                            <h3 className={themes[currentTheme].heading3}>
                              {subSection.H3Title}
                            </h3>
                            <RegenerateSection
                              articleId={id}
                              h2Title={section.H2Title}
                              h3Title={subSection.H3Title}
                              notes={subSection.notes}
                              currentTheme={currentTheme}
                              onRegenerateComplete={(newContent) => {
                                const updatedArticle = {
                                  ...article,
                                  outline: {
                                    ...article.outline,
                                    H2Sections: article.outline.H2Sections.map((h2Section, h2Index) => {
                                      if (h2Index === index) {
                                        return {
                                          ...h2Section,
                                          H3Sections: h2Section.H3Sections.map((h3Section, h3Index) => {
                                            if (h3Index === subIndex) {
                                              return {
                                                ...h3Section,
                                                content: newContent
                                              };
                                            }
                                            return h3Section;
                                          })
                                        };
                                      }
                                      return h2Section;
                                    })
                                  }
                                };
                                setArticle(updatedArticle);
                              }}
                            />
                          </div>
                          <div className={`prose max-w-none ${currentTheme === 'modern' ? 'prose-invert' : ''}`}>
                            {subSection.content && (
                              <ContentParser text={subSection.content} />
                            )}
                          </div>
                        </div>
                      ))}
                      
                      {/* CTA Button at the end of each H2 section */}
                      {section.ctaButton && (
                        <div className="flex justify-center">
                          <CTAButton text={section.ctaButton} />
                        </div>
                      )}
                    </div>
                  ))}

                  {/* FAQ Section */}
                  {article.faq && article.faq.length > 0 && (
                    <div className="mt-12 scroll-mt-24" id="faq">
                      <h2 className={themes[currentTheme].heading2}>
                        よくある質問
                      </h2>
                      <div className="space-y-6">
                        {article.faq.map((faq, index) => (
                          <div key={index} className={themes[currentTheme].faqContainer}>
                            <h3 className={themes[currentTheme].heading3}>
                              {faq.question}
                            </h3>
                            <p className={themes[currentTheme].paragraph}>{faq.answer}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Metadata */}
                  <div className={`mt-12 pt-6 border-t ${currentTheme === 'modern' ? 'border-slate-700' : 'border-gray-200'}`}>
                    <div className="flex justify-between text-sm">
                      <div className={themes[currentTheme].metadata}>
                        更新日: {new Date(article.metadata.updatedAt).toLocaleDateString()}
                      </div>
                    </div>
                  </div>
                  <DownloadButtons articleId={id} currentTheme={currentTheme} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ThemeContext.Provider>
  );
};

export default MyArticleView;