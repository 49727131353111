import React from 'react';
import { useUser } from '@clerk/clerk-react';
import { Navigate, useLocation } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';

const ProtectedRoute = ({ children }) => {
  const { isLoaded, isSignedIn } = useUser();
  const location = useLocation();

  if (!isLoaded) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!isSignedIn) {
    // Redirect to sign-in page if not logged in
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  // User is logged in and approved, show the protected content
  return children;
};

export default ProtectedRoute;