import React from 'react';
import { useUser, SignedIn, SignedOut, RedirectToSignIn } from '@clerk/clerk-react';
import { Link } from 'react-router-dom';
import PageIllustration from './PageIllustration';
import { useUserContext } from '../contexts/UserContext';
import TransactionHistory from './credit/TransactionHistory';
import { getPlanNameInJapanese, getStatusInJapanese } from '../utils/subscriptionUtils';

function UserProfile() {
  const { isLoaded, user } = useUser();
  const { credits, subscription, loading: creditsLoading } = useUserContext();

  if (!isLoaded) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const renderCreditInfo = () => {
    if (creditsLoading) {
      return (
        <div className="animate-pulse space-y-2">
          <div className="h-8 w-24 bg-gray-200 rounded"></div>
          <div className="h-6 w-32 bg-gray-200 rounded"></div>
        </div>
      );
    }

    return (
      <div className="space-y-2">
        <div className="flex items-center gap-2">
          <h4 className="text-lg font-semibold text-gray-900">利用可能なクレジット</h4>
          <span className="text-2xl font-bold text-gray-900">{credits.available}</span>
        </div>
        <div className="flex items-center gap-4">
          <span className="text-sm text-gray-600">
            予約済み: {credits.reserved}
          </span>
          <span className="text-sm text-gray-600">
            合計: {credits.total}
          </span>
        </div>
      </div>
    );
  };

  const renderSubscriptionInfo = () => {
    if (!subscription) return null;

    return (
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <h4 className="text-lg font-semibold text-gray-900">現在のプラン</h4>
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            {getStatusInJapanese(subscription.status)}
          </span>
        </div>
        <p className="text-gray-600">
          {getPlanNameInJapanese(subscription.plan)}
        </p>
        {subscription.current_period_end && (
          <p className="text-sm text-gray-500">
            次回更新日: {new Date(subscription.current_period_end).toLocaleDateString('ja-JP')}
          </p>
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="space-y-8" data-aos="zoom-y-out" data-aos-delay="300">
        {/* Profile Card */}
        <div className="max-w-3xl mx-auto rounded-2xl bg-white/90 p-6 shadow-xl backdrop-blur-sm">
          <div className="flex flex-col sm:flex-row items-center sm:items-start gap-6">
            <div className="shrink-0">
              <img
                src={user.imageUrl}
                alt={user.firstName}
                className="h-24 w-24 rounded-full object-cover shadow-lg"
              />
            </div>
            <div className="flex-1 text-center sm:text-left">
              <h2 className="text-2xl font-bold tracking-[-0.037em] text-gray-800 mb-2">
                {user.firstName} {user.lastName}
              </h2>
              <p className="text-gray-600 mb-2">{user.primaryEmailAddress?.emailAddress}</p>
            </div>
          </div>
        </div>

        {/* Credit Information */}
        <div className="max-w-3xl mx-auto rounded-2xl bg-white/90 p-6 shadow-xl backdrop-blur-sm">
          <div className="space-y-6">
            {renderCreditInfo()}
            {renderSubscriptionInfo()}
            <div className="flex justify-end">
              <Link
                to="/pricing"
                className="inline-flex items-center justify-center rounded-xl bg-gray-800 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              >
                クレジットを購入
              </Link>
            </div>
          </div>
        </div>

        {/* Transaction History */}
        <div className="max-w-3xl mx-auto rounded-2xl bg-white/90 p-6 shadow-xl backdrop-blur-sm">
          <TransactionHistory />
        </div>

        {/* Quick Actions */}
        <div className="max-w-md mx-auto space-y-4">
          <Link
            to="/articles"
            className="group relative flex w-full items-center justify-between rounded-2xl bg-white/90 p-6 shadow-xl backdrop-blur-sm transition-all hover:shadow-2xl"
          >
            <div>
              <h3 className="text-xl font-bold text-gray-800 mb-1">記事一覧</h3>
              <p className="text-sm text-gray-600">これまでに生成した記事を確認・編集</p>
            </div>
            <span className="ml-4 text-gray-400 transition-transform duration-150 ease-in-out group-hover:translate-x-1">
              →
            </span>
          </Link>

          <Link
            to="/create"
            className="group relative flex w-full items-center justify-between rounded-2xl bg-white/90 p-6 shadow-xl backdrop-blur-sm transition-all hover:shadow-2xl"
          >
            <div>
              <h3 className="text-xl font-bold text-gray-800 mb-1">新規作成</h3>
              <p className="text-sm text-gray-600">詳細設定で記事を生成</p>
            </div>
            <span className="ml-4 text-gray-400 transition-transform duration-150 ease-in-out group-hover:translate-x-1">
              →
            </span>
          </Link>

          <Link
            to="/quick-generate"
            className="group relative flex w-full items-center justify-between rounded-2xl bg-white/90 p-6 shadow-xl backdrop-blur-sm transition-all hover:shadow-2xl"
          >
            <div>
              <h3 className="text-xl font-bold text-gray-800 mb-1">クイック生成</h3>
              <p className="text-sm text-gray-600">キーワードだけで簡単に記事を生成</p>
            </div>
            <span className="ml-4 text-gray-400 transition-transform duration-150 ease-in-out group-hover:translate-x-1">
              →
            </span>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <section className="relative min-h-screen">
      <PageIllustration />
      <SignedIn>
        <div className="relative z-10 mx-auto max-w-6xl px-4 sm:px-6">
          <div className="pb-12 pt-32 md:pb-20 md:pt-40">
            {renderContent()}
          </div>
        </div>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </section>
  );
}

export default UserProfile;