import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import axios from 'axios';
import PageIllustration from './PageIllustration';
import { Pencil } from 'lucide-react';

function OutlineDisplay() {
  const [outline, setOutline] = useState(null);
  const [loading, setLoading] = useState(true);
  const [generating, setGenerating] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { getToken } = useAuth();

  useEffect(() => {
    const fetchOutline = async () => {
      try {
        const token = await getToken();
        
        if (!token) {
          console.error('No authentication token found');
          navigate('/sign-in');
          return;
        }

        const response = await axios.get(`/api/outlines/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        console.log('Outline data:', response.data);
        setOutline(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching outline:', error);
        if (error.response && error.response.status === 401) {
          navigate('/sign-in');
          return;
        }
        setLoading(false);
      }
    };

    fetchOutline();
  }, [id, getToken, navigate]);

  const handleGenerateArticle = async () => {
    try {
      setGenerating(true);
      
      const token = await getToken();
      
      if (!token) {
        console.error('No authentication token found');
        navigate('/sign-in');
        return;
      }
      
      const response = await axios.post(
        `/api/generate-article-from-outline/${id}`,
        {
          outline: {
            title: outline.title,
            intro: outline.intro,
            outline: outline.outline,
            faq: outline.faq
          }
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.data.id) {
        navigate(`/my_articles/${response.data.id}`);
      }
    } catch (error) {
      console.error('Error generating article:', error);
      if (error.response && error.response.status === 401) {
        navigate('/sign-in');
      }
    } finally {
      setGenerating(false);
    }
  };

  if (loading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-2 border-gray-800 border-t-transparent"></div>
      </div>
    );
  }

  if (!outline) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <p className="text-gray-600">アウトラインが見つかりませんでした。</p>
      </div>
    );
  }

  const isQuickOutline = !outline.intro && !outline.faq;

  return (
    <section className="relative min-h-screen">
      <PageIllustration />
      <div className="relative z-10 mx-auto max-w-4xl px-4 sm:px-6">
        <div className="pt-24 text-center md:pt-32">
          <h1 className="mb-4 text-2xl font-bold text-gray-800 md:text-3xl">
            アウトライン
          </h1>
        </div>
        
        <div className="pb-12 pt-8 md:pb-20">
          <article className="relative overflow-hidden rounded-2xl bg-white/90 p-6 shadow-lg backdrop-blur-sm md:p-8">
            <Link 
              to={`/edit-outline/${id}`}
              className="absolute right-4 top-4 rounded-lg bg-gray-100 p-2 text-gray-600 hover:bg-gray-200 hover:text-gray-800 transition-colors md:right-6 md:top-6"
            >
              <Pencil size={20} />
            </Link>

            <h1 className="mb-6 text-3xl font-bold tracking-[-0.037em] text-gray-800 md:text-4xl">
              <span className="mr-2 text-gray-500">H1:</span>
              {outline.title}
            </h1>

            {!isQuickOutline && outline.intro && (
              <div className="mb-8 text-lg leading-relaxed text-gray-600">
                {outline.intro}
              </div>
            )}

            {outline.targetAudience && (
              <div className="mb-8 text-lg text-gray-600">
                <strong>ターゲット読者:</strong> {outline.targetAudience}
              </div>
            )}

            {outline.outline?.H2Sections && outline.outline.H2Sections.map((section, index) => (
              <div key={index} className="mb-8">
                <h2 className="mb-4 text-2xl font-bold text-gray-800">
                  <span className="mr-2 text-gray-500">H2:</span>
                  {section.H2Title}
                </h2>
                {section.ctaButton && (
                  <div className="mb-4">
                    <button className="rounded-lg bg-blue-600 px-4 py-2 text-white hover:bg-blue-700">
                      {section.ctaButton}
                    </button>
                  </div>
                )}
                {section.H3Sections && section.H3Sections.map((h3Section, h3Index) => (
                  <div key={h3Index} className="mb-6">
                    <h3 className="mb-2 text-xl font-semibold text-gray-800">
                      <span className="mr-2 text-gray-500">H3:</span>
                      {h3Section.H3Title}
                    </h3>
                    {h3Section.notes && (
                      <div className="mb-2 text-gray-600">
                        <strong>キーポイント:</strong> {h3Section.notes}
                      </div>
                    )}
                  </div>
                ))}
                {section.sectionImage?.url && (
                  <div className="mt-4 overflow-hidden rounded-xl">
                    <img
                      src={section.sectionImage.url}
                      alt={section.H2Title}
                      className="h-auto w-full"
                    />
                  </div>
                )}
              </div>
            ))}

            {!isQuickOutline && outline.faq && outline.faq.length > 0 && (
              <>
                <div className="my-8 h-px bg-gray-200"></div>
                <div className="mb-8">
                  <h2 className="mb-6 text-2xl font-bold text-gray-800">
                    <span className="mr-2 text-gray-500">H2:</span>
                    よくある質問
                  </h2>
                  <div className="space-y-6">
                    {outline.faq.map((faq, index) => (
                      <div key={index} className="rounded-xl bg-gray-50 p-6">
                        <h3 className="mb-3 text-xl font-semibold text-gray-800">
                          {faq.question}
                        </h3>
                        <div className="text-gray-600">
                          {faq.answer}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}

            <div className="mt-8 flex justify-center">
              <button
                onClick={handleGenerateArticle}
                disabled={generating}
                className="rounded-lg bg-blue-600 px-6 py-3 text-white hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed transition-colors"
              >
                {generating ? (
                  <div className="flex items-center space-x-2">
                    <div className="h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent"></div>
                    <span>記事を生成中...</span>
                  </div>
                ) : (
                  '記事を生成する'
                )}
              </button>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
}

export default OutlineDisplay;