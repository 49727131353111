import React, { useState } from 'react';
import { Download } from 'lucide-react';
import { useAuth } from "@clerk/clerk-react";

const DownloadButtons = ({ articleId, currentTheme }) => {
    const [downloading, setDownloading] = useState(false);
    const { getToken } = useAuth();

    const handleDownload = async (format) => {
        try {
            setDownloading(true);
            const token = await getToken();

            const response = await fetch(
                `/api/articles/${articleId}/download/${format}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Download failed');
            }

            // Get filename from response headers if present
            const contentDisposition = response.headers.get('content-disposition');
            const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
            const filename = filenameMatch ? filenameMatch[1] : `article.${format}`;

            // Create blob and download
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

        } catch (error) {
            console.error('Download error:', error);
            alert('ダウンロードに失敗しました。もう一度お試しください。');
        } finally {
            setDownloading(false);
        }
    };

    const buttonClass = currentTheme === 'modern' 
        ? 'bg-slate-800 hover:bg-slate-700 text-white'
        : currentTheme === 'generated'
        ? 'bg-gray-800 hover:bg-gray-700 text-white'
        : 'bg-blue-600 hover:bg-blue-700 text-white';

    return (
        <div className="flex gap-4 mt-6 justify-center">
            <button
                onClick={() => handleDownload('word')}
                disabled={downloading}
                className={`${buttonClass} px-4 py-2 rounded-lg flex items-center gap-2 transition-colors duration-150 disabled:opacity-50`}
            >
                <Download size={18} />
                Word形式
            </button>
            <button
                onClick={() => handleDownload('pdf')}
                disabled={downloading}
                className={`${buttonClass} px-4 py-2 rounded-lg flex items-center gap-2 transition-colors duration-150 disabled:opacity-50`}
            >
                <Download size={18} />
                PDF形式
            </button>
        </div>
    );
};

export default DownloadButtons;