import axios from 'axios';
import { config } from '../config/environment';

const createAuthenticatedApi = (baseURL) => {
  const api = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

  api.interceptors.request.use(async (config) => {
    try {
      const session = await window.Clerk?.session;
      if (!session) {
        throw new Error('アクティブなセッションがありません');
      }

      const token = await session.getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } catch (error) {
      console.error('Auth configuration error:', error);
      throw error;
    }
  });

  return api;
};

const api = createAuthenticatedApi(`${config.apiBaseUrl}`);

export const subscriptionService = {
  getCurrentSubscription: async () => {
    try {
      const response = await api.get('/api/subscriptions/current');
      console.log('Subscription response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching subscription:', error);
      if (error.response?.status === 401) {
        throw new Error('認証が必要です。再度ログインしてください。');
      }
      throw new Error('サブスクリプション情報の取得に失敗しました');
    }
  },

  // Create checkout session
  createCheckoutSession: async ({ priceId, isYearly }) => {
    try {
      const response = await api.post('/api/subscriptions/create-checkout', {
        priceId,
        isYearly
      });
      return response.data;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw new Error('チェックアウトの作成に失敗しました');
    }
  },

  // Create customer portal session
  createPortalSession: async () => {
    try {
      const response = await api.post('/api/subscriptions/create-portal', {
        returnUrl: `${window.location.origin}/profile`
      });
      return response.data;
    } catch (error) {
      console.error('Error creating portal session:', error);
      throw new Error('カスタマーポータルの作成に失敗しました');
    }
  },

  // Cancel subscription
  cancelSubscription: async () => {
    try {
      const response = await api.post('/api/subscriptions/cancel');
      return response.data;
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      throw new Error('サブスクリプションのキャンセルに失敗しました');
    }
  },

  // Resume subscription
  resumeSubscription: async () => {
    try {
      const response = await api.post('/api/subscriptions/resume');
      return response.data;
    } catch (error) {
      console.error('Error resuming subscription:', error);
      throw new Error('サブスクリプションの再開に失敗しました');
    }
  },

  // Get subscription history
  getSubscriptionHistory: async () => {
    try {
      const response = await api.get('/api/subscriptions/history');
      return response.data;
    } catch (error) {
      console.error('Error fetching subscription history:', error);
      throw new Error('サブスクリプション履歴の取得に失敗しました');
    }
  },

  // Verify checkout session
  verifyCheckoutSession: async (sessionId) => {
    try {
      const response = await api.get(`/api/subscriptions/verify-session/${sessionId}`);
      return response.data;
    } catch (error) {
      console.error('Error verifying checkout session:', error);
      throw new Error('チェックアウトセッションの検証に失敗しました');
    }
  },

  // Get upcoming invoice
  getUpcomingInvoice: async () => {
    try {
      const response = await api.get('/api/subscriptions/upcoming-invoice');
      return response.data;
    } catch (error) {
      console.error('Error fetching upcoming invoice:', error);
      throw new Error('次回の請求情報の取得に失敗しました');
    }
  },

  // Update payment method
  updatePaymentMethod: async () => {
    try {
      const response = await api.post('/api/subscriptions/update-payment');
      return response.data;
    } catch (error) {
      console.error('Error updating payment method:', error);
      throw new Error('支払い方法の更新に失敗しました');
    }
  },

  // Update subscription
  updateSubscription: async (params) => {
    try {
      const response = await api.post('/api/subscriptions/update', params);
      return response.data;
    } catch (error) {
      console.error('Error updating subscription:', error);
      throw new Error('サブスクリプションの更新に失敗しました');
    }
  }
};

// Error handler helper
export const handleSubscriptionError = (error) => {
  const defaultMessage = 'エラーが発生しました。後でもう一度お試しください。';
  
  if (error.response) {
    // Server responded with error
    switch (error.response.status) {
      case 401:
        return 'ログインが必要です。';
      case 402:
        return '支払いに問題が発生しました。';
      case 403:
        return 'この操作を行う権限がありません。';
      case 404:
        return 'サブスクリプション情報が見つかりません。';
      case 429:
        return 'リクエストが多すぎます。しばらく待ってから再試行してください。';
      default:
        return error.response.data?.message || defaultMessage;
    }
  }
  
  if (error.request) {
    // No response received
    return 'サーバーに接続できません。インターネット接続を確認してください。';
  }
  
  // Something else went wrong
  return error.message || defaultMessage;
};